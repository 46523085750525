import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';

import { CartContextProvider } from "./contexts/CartContext";
import { ResContextProvider } from "./contexts/ResContext";

import NavBar from "./components/NavBar";
import Home from "./components/body/Home";
import Blog from "./components/body/Blog";
import About from "./components/body/About";
import Contact from "./components/body/Contact";
import Games from "./components/body/Games";

function App() {

  return (
      <ResContextProvider>
        <CartContextProvider>
          <div className="App">
            <Router>
              <NavBar />
              <div className="body-container">
                <Routes>
                  <Route path="/" Component={Home} />
                  <Route path="/about" Component={About} />
                  <Route path="/blog" Component={Blog} />
                  <Route path="/contact" Component={Contact} />
                  <Route path="/games" Component={Games} />
                </Routes>
              </div>
            </Router>
          </div>
        </CartContextProvider>
      </ResContextProvider>
  );
}

export default App;
