import React, { useState } from "react";

import "./Home.css";

import Banner from "../Banner";
import MenuCategories from "../MenuCategories";
import CartModal from "../CartModal";

function Home() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="homeContainer">
            
            <Banner />

            <MenuCategories />

            <CartModal isOpen={isModalOpen} onClose={closeModal} />
            
            <div className="cartIconContainer">
                <img 
                    src={require("../../icons/cart.png")}
                    alt=""
                    onClick={openModal}
                    >   
                    </img>
            </div>
        
        </div>
    );
  }
  
  export default Home;