import React, { useEffect, useState, useContext } from "react"

import { CartContext } from "../contexts/CartContext";

import './CartButton.css';

//product counter component
function CartButton( {updateCount, food} ) {
  const [qty, setQty] = useState(0)
  const { cart, setCart } = useContext(CartContext);

// const toggleVisibility = () => { I don't think it's necessary because increaseQty is doing its work but keeping in for now
//     setQty(1);
// }

const decreaseQty = () => {
  setQty(qty - 1);
}

const increaseQty = () => {
  setQty(qty + 1);
}

//add and remove items to/from the cart
const updateCart = () => {
  const cartItem = [
    {id: food.ItemId, name: food.ItemName, price: food.ItemPrice, amount: food.ItemPrice*qty, count: qty},
  ];

  /* eslint eqeqeq: 0 */ //to avoid the eqeqeq warning
  if (qty == 0) {
    if (JSON.stringify(cart).includes(food.ItemName))
    {
      for (var i=0; i<cart.length; i++)
      {
        if (JSON.stringify(cart[i]).includes(food.ItemName))
        {
          cart.splice(i, 1);
          //alert(JSON.stringify(cart));
          //setCart(...cart);
          setCart(cart.filter(item => item.name !== food.ItemName)) //remove that element from the cart
          break;
        }
      }
    }
  }
  else {
    if (JSON.stringify(cart).includes(food.ItemName))
    {
      // eslint-disable-next-line
      for (var i=0; i<cart.length; i++)
      {
        if (JSON.stringify(cart[i]).includes(food.ItemName))
        {
          cart[i] = cartItem;
          //alert(JSON.stringify(cart));
          //setCart(cart[i] = cartItem);
          setCart(cart.map(item => item.name === food.ItemName ? cartItem : item))  //update the item's value
          break;
        }
      }
    }
    else {
      setCart(cart => [...cart, cartItem], () => {  //add the item
        //alert(JSON.stringify(cart));
      });
    }
  }
};

  //will update the pdt qty value when qty is changed
  useEffect(() => {
    updateCount(qty);
    updateCart();
  }, [qty])

  
  
  //input with change in qty listener to setQty
  return (
    <div className="input-group rounded">

      {qty !== 0 ? 
      <div className="productCounter">
        <button className="minusButton" onClick={() => decreaseQty()}>-</button>
        <button className="qtyButton" disabled>{qty}</button>
        <button className="plusButton" onClick={() => increaseQty()}>+</button>
      </div>
       : 
       <button className="addToCartButton" onClick={() => increaseQty()}>Add</button>
       }
    </div>
  );
}

export default CartButton;