import React, { useState } from "react";
import "./Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Web app URL from Google Apps Script
    const scriptURL = 'https://script.google.com/macros/s/AKfycbynDrvebCfGDGGlyEUVseRHJz7Oqt6IRaldZ984dZxtBZ2pspuLbz-Jrbx52aiTDEYn/exec';
    
    // Prepare the form data
    const dataToSend = new FormData();
    dataToSend.append('name', formData.name);
    dataToSend.append('email', formData.email);
    dataToSend.append('message', formData.message);
  
    // Send the form data to the Google Apps Script
    fetch(scriptURL, { method: 'POST', body: dataToSend })
      .then(response => response.text())
      .then(result => {
        console.log('Success:', result);
        setSubmitted(true);
      })
      .catch(error => {
        console.error('Error:', error);
        alert('There was an issue submitting the form. Please try again later.');
      });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <h2>Get in Touch</h2>
        <p>
          If you have any questions or need support, feel free to reach out to us using the contact details below or by filling out the form.
        </p>
        <div className="contact-details">
          <p><strong>Email:</strong> customer@spadeleaf.com</p>
          <p><strong>Phone:</strong> (+91) 7002541678</p>
          <p><strong>Address:</strong> AMR Salar, Ejipura, 700047</p>
        </div>
      </div>
      <div className="contact-form">
        <h2>Send Us a Message</h2>
        {submitted ? (
          <p className="form-submitted">Thank you for your message! We will get back to you soon.</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit">Send Message</button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Contact;
