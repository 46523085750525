import React from "react";

function Blog() {
    return (
      <div>
        hello
      </div>
    );
  }
  
  export default Blog;