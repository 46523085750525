import React, { useState, useEffect, useContext } from "react";
import axios from "../services/axios";
import { ResContext } from "../contexts/ResContext";

import "./MenuCategories.css";

import MenuItem from "./MenuItem";


function MenuCategories() {
    const [foodCategories, setFoodCategories] = useState( [] );
    const [activeCategory, setActiveCategory] = useState(0);
    const { resId, resJoin } = useContext(ResContext);

    const handleLinkClick = (index) => {
        
        setActiveCategory(index);
        // Add any other logic you want to perform when a link is clicked
      };

    useEffect( () =>{
        async function fetchData() {
            try {
                const response = await axios("spadeleaf-api.php?action=getFoodCategoryByCust&resId=" + resId + "&resJoin=" + resJoin);
                setFoodCategories(response.data);
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }, []); 

    if (!foodCategories || foodCategories.length === 0 || activeCategory >= foodCategories.length) {
        return null; // or handle this case accordingly
      }

    return (
        <div className="menuCategories" id="menuCategories">

            {foodCategories.map((foodCategory, index) => (
                <a
                key={index}
                    href="#menuCategories"  //maybe I'll keep maybe I'll remove! 
                    onClick={() => handleLinkClick(index)}
                    className={index === activeCategory ? "active" : "inactive"}>
                    |{foodCategory.category}
                </a>
            ))}

            <MenuItem category={foodCategories[activeCategory]["categoryId"]}/>
            
        </div>
    );
  }
  
  export default MenuCategories;