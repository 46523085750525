import React, { useState, useEffect, createContext } from "react";

export const ResContext = createContext();

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

export const ResContextProvider = ({ children }) => {
  const [resId, setResId] = useState(params.resId ? params.resId : null);
  const [resJoin, setResJoin] = useState(params.resJoin ? params.resJoin : null);
  const [table, setTable] = useState(params.table ? params.table : 0);
  const [mode, setMode] = useState(params.mode ? params.mode : 'post');

  useEffect(() => {
    if (params.resId) {
      setResId(params.resId);
    }
    if (params.resJoin) {
      setResJoin(params.resJoin);
    }
    if (params.table) {
      setTable(params.table);
    }
    if (params.mode === 'pre') {
      setMode(params.mode);
    }
  
  }, [resId])

  return (
    <ResContext.Provider
      value={{
       resId,
       setResId,
       resJoin,
       setResJoin,
       table,
       setTable,
       mode,
       setMode,
      }}
    >
      {children}
    </ResContext.Provider>
  );
};