import React, { useState, useEffect } from 'react';
import axios from "../services/axios";
import './Orders.css';

function Orders({ order, reduceCheckoutAmount, mode, clearOrders, serviceCharge, taxes }) {
  const [status, setStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.post("spadeleaf-api.php?action=fetchOrderStatus", {
          orderId: order.orderId
        });
        if (response.data.orderStatus === "0") {
          setStatus("Order Placed");
        } else if (response.data.orderStatus === "1") {
          setStatus("Preparing...");
        } else if (response.data.orderStatus === "2") {
          setStatus("Completed");
          // if (mode === "pre") {
          //   clearOrders(); // Clear orders from local storage once the pre-order is completed
          // }
          if(response.data.paymentStatus === "1") {
            clearOrders();
          }
        } else if (response.data.orderStatus === "3") {
          setStatus("Rejected");
          reduceCheckoutAmount(order.totalAmount, order.orderId);
        } else {
          alert(response.data.orderStatus);
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    // Fetch data every 40 seconds
    const interval = setInterval(fetchData, 40000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [status, order.orderId, reduceCheckoutAmount, clearOrders, mode]);

  return (
    <div className='order'>
      <p><b>{status}</b></p>
      <table>
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {order.items.map((item) => {
            const itemName = item[0].name;
            const quantity = item[0].count;
            const amount = item[0].amount.toFixed(2); // Format amount to 2 decimal places

            return (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>x{quantity}</td>
                <td>₹{amount}</td>
              </tr>
            );
          })}
          {order.serviceChargeAmount > 0 && (
            <tr>
              <td>Service Charges</td>
              <td>{serviceCharge}%</td>
              <td>₹{parseFloat(order.serviceChargeAmount).toFixed(2)}</td>
            </tr>
          )}
          {order.taxAmount > 0 && (
            <tr>
              <td>Taxes</td>
              <td>{taxes}%</td>
              <td>₹{parseFloat(order.taxAmount).toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <td colSpan="2"><b>Total</b></td>
            <td><b>₹{parseFloat(order.totalAmount).toFixed(2)}</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Orders;
