import React, { useState, useContext, useEffect } from "react";
import axios from "../services/axios";
import { ResContext } from "../contexts/ResContext";
import "./CartModal.css";
import Orders from "./Orders";
import { CartContext } from "../contexts/CartContext";

const CartModal = ({ isOpen, onClose }) => {
  const { cart, setCart } = useContext(CartContext);
  const [orders, setOrders] = useState([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [rejectedOrders, setRejectedOrders] = useState([]);
  const [serviceCharge, setServiceCharge] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const { resId, table, mode } = useContext(ResContext);

  const [isPhoneValid, setIsPhoneValid] = useState(false);

  let cartAmount = 0;

  cart.forEach(item => {
    cartAmount += item[0].amount;
  });

  const serviceChargeAmount = (cartAmount * (serviceCharge / 100)).toFixed(2);
  const taxAmount = ((cartAmount + parseFloat(serviceChargeAmount)) * (taxes / 100)).toFixed(2);
  const totalPayable = (parseFloat(cartAmount) + parseFloat(serviceChargeAmount) + parseFloat(taxAmount)).toFixed(2);

  const updateCartList = () => {
    return (
      <table className="cartTable">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Quantity</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item) => {
            const itemName = item[0].name;
            const quantity = item[0].count;
            const amount = item[0].amount.toFixed(2);

            return (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>x{quantity}</td>
                <td>₹{amount}</td>
              </tr>
            );
          })}
          {cartAmount > 0 && (
            <>
              {serviceCharge !== null && (
                <tr>
                  <td>Service Charges</td>
                  <td>{serviceCharge}%</td>
                  <td>₹{serviceChargeAmount}</td>
                </tr>
              )}
              {taxes !== null && (
                <tr>
                  <td>Taxes</td>
                  <td>{taxes}%</td>
                  <td>₹{taxAmount}</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  };

  const updateTotalAmount = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>Total =</td>
            <td>₹{totalPayable}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    localStorage.setItem("customerName", newName);
    localStorage.setItem("customerNameTimestamp", new Date().getTime());
  };

  const handlePhoneChange = (event) => {
    const newPhone = event.target.value;
    setPhone(newPhone);
    localStorage.setItem("customerPhone", newPhone);
    localStorage.setItem("customerPhoneTimestamp", new Date().getTime());

    // Validate phone number
    const isValid = /^\d{10}$/.test(newPhone);
    setIsPhoneValid(isValid);
  };

  const placeOrder = () => {
    async function fetchData() {
      try {
        const response = await axios.post("spadeleaf-api.php?action=placeOrder&resId=" + resId + "&table=" + table, {
          items: cart,
          customerName: name,
          customerPhone: phone,
          amount: totalPayable,
          taxes: taxes,
          serviceCharge: serviceCharge,
          mode: mode === "pre" ? "pre" : "post",
        });
        if (response.data.message === 1) {
          const newOrder = { items: cart, totalAmount: totalPayable, orderId: response.data.orderId, serviceChargeAmount, taxAmount };
          setOrders(prevOrders => [...prevOrders, newOrder]);
          setTotalAmount(prevTotal => prevTotal + parseFloat(totalPayable));
          localStorage.setItem("orders", JSON.stringify([...orders, newOrder]));
          localStorage.setItem("ordersTimestamp", new Date().getTime());
          localStorage.setItem("totalAmount", parseFloat(totalAmount) + parseFloat(totalPayable));
          localStorage.setItem("totalAmountTimestamp", new Date().getTime());
          setCart([]);
        } else {
          alert(response.data.message);
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  };

  const handleCheckout = () => {
    let link_id;

    async function fetchData() {
      try {
        const response = await axios.post("spadeleaf-api.php?action=paymentRequest&resId=" + resId + "&table=" + table, {
          items: mode === "pre" ? cart : orders,
          customerName: name,
          customerPhone: phone,
          amount: mode === "pre" ? totalPayable : totalAmount,
        });
        if (response.data && response.data.link_url) {
          window.open(response.data.link_url, '_blank');
          link_id = response.data.link_id;

          const pollPaymentStatus = setInterval(async () => {
            try {
              const statusResponse = await axios.post("spadeleaf-api.php?action=paymentSuccessful&linkId=" + link_id, {
                orders: orders,
                customerName: name,
                customerPhone: phone,
                resId: resId,
                amount: mode === "pre" ? totalPayable : totalAmount,
              });
              if (statusResponse.data.status === "success") {
                clearInterval(pollPaymentStatus);
                if (mode === "pre") {
                  placeOrder();
                  alert("Payment completed. Sit tight!");
                } else {
                  alert("Payment completed");
                  localStorage.removeItem('orders');
                  setOrders([]);
                  setTotalAmount(0);
                  onClose();
                }
                localStorage.removeItem('orders');
                localStorage.removeItem('totalAmount');
                setOrders([]);
                setTotalAmount(0);
                onClose();
              }
            } catch (error) {
              console.error("Error occurred while checking payment status:", error);
            }
          }, 5000);
        } else {
          // alert("Network error! Please try again.");
        }
      } catch (error) {
        console.error("Error occurred during payment:", error);
      }
    }

    fetchData();
  };

  const reduceCheckoutAmount = (amount, orderId) => {
    if (!rejectedOrders.includes(orderId)) {
      setTotalAmount(prevTotal => prevTotal - amount);
      setRejectedOrders(prevRejected => [...prevRejected, orderId]);
    }
  };

  const clearOrders = () => {
    localStorage.removeItem('orders');
    localStorage.removeItem('totalAmount');
    setOrders([]);
    setTotalAmount(0);
  };

  useEffect(() => {
    const storedOrders = JSON.parse(localStorage.getItem("orders"));
    if (storedOrders) {
      setOrders(storedOrders);
    }
    const checkoutAmount = localStorage.getItem("totalAmount");
    if (checkoutAmount) {
      setTotalAmount(checkoutAmount);
    }
    const storedName = localStorage.getItem("customerName");
    if (storedName) {
      setName(storedName);
    }
    const storedPhone = localStorage.getItem("customerPhone");
    if (storedPhone) {
      setPhone(storedPhone);
      setIsPhoneValid(/^\d{10}$/.test(storedPhone));
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios("spadeleaf-api.php?action=getAdditionalCharges&resId=" + resId);
        if (response.data.serviceCharges) {
          setServiceCharge(response.data.serviceCharges);
        }
        if (response.data.taxes) {
          setTaxes(response.data.taxes);
        }
      } catch (error) {
        console.log(error);
        // alert("Failed to fetch taxes. Kindly reload.")
      }
    }
    fetchData();
  }, []);

  //temporary code to clear deleted orders
  // useEffect(() => {
  //   clearOrders()
  // }, []);

  useEffect(() => {
    const oneDayInMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds
    const now = new Date().getTime();
  
    const checkAndClearLocalStorage = (key, timestampKey) => {
      const storedTimestamp = localStorage.getItem(timestampKey);
      if (storedTimestamp && now - parseInt(storedTimestamp, 10) > oneDayInMs) {
        localStorage.removeItem(key);
        localStorage.removeItem(timestampKey);
      }
    };
  
    // Check and clear 'orders', 'totalAmount', 'customerName', and 'customerPhone' if older than 1 day
    checkAndClearLocalStorage("orders", "ordersTimestamp");
    checkAndClearLocalStorage("totalAmount", "totalAmountTimestamp");
    checkAndClearLocalStorage("customerName", "customerNameTimestamp");
    checkAndClearLocalStorage("customerPhone", "customerPhoneTimestamp");
  
    // If localStorage data is still valid, set state from localStorage
    const storedOrders = JSON.parse(localStorage.getItem("orders"));
    if (storedOrders) {
      setOrders(storedOrders);
    }
  
    const checkoutAmount = localStorage.getItem("totalAmount");
    if (checkoutAmount) {
      setTotalAmount(checkoutAmount);
    }
  
    if (localStorage.getItem("customerName")) {
      setName(localStorage.getItem("customerName"));
    }
  
    if (localStorage.getItem("customerPhone")) {
      setPhone(localStorage.getItem("customerPhone"));
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      const storedPhone = localStorage.getItem("customerPhone");
      if (storedPhone) {
        setPhone(storedPhone);
        setIsPhoneValid(/^\d{10}$/.test(storedPhone));
      }
    }
  }, [isOpen]);
  

  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={onClose}>
              &times;
            </span>
            <h2 className="cartTitle">Your Cart</h2>
            {mode === "pre" && <p>Please make the payment to complete the order</p>}
            <ul>
              {updateCartList()}
              {updateTotalAmount()}
            </ul>
            {cartAmount > 0 && (
              <>
                <input type="text" value={name} required onChange={handleNameChange} placeholder="Name" />
                <input type='tel' pattern='[0-9]{10}' placeholder='Phone number' required value={phone} onChange={handlePhoneChange} />
                {mode !== "pre" && <button className="placeOrderButton" onClick={placeOrder} disabled={!isPhoneValid}>Place order</button>}
                {mode === "pre" && <button className="checkoutButton" onClick={handleCheckout}  disabled={!isPhoneValid}>Checkout | ₹{totalPayable}</button>}
              </>
            )}
            {totalAmount > 0 &&
              orders.map((order, index) => (
                <Orders key={index} order={order} reduceCheckoutAmount={reduceCheckoutAmount} clearOrders={clearOrders} mode={mode} serviceCharge={serviceCharge} taxes={taxes} />
              ))
            }
            {totalAmount > 0 && mode !== "pre" && <button className="checkoutButton" onClick={handleCheckout} disabled={!isPhoneValid}>Checkout | ₹{parseFloat(totalAmount).toFixed(2)}</button>}
          </div>
        </div>
      )}
    </>
  );
};

export default CartModal;
