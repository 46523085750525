import React, { useEffect, useState, useContext } from "react";
import { ResContext } from "../contexts/ResContext";
import axios from "../services/axios";

import "./Banner.css";

function Banner() {
  const [resName, setResName] = useState(null);
  const { resId, resJoin } = useContext(ResContext);

  useEffect( () =>{
    async function fetchData() {
        try {
            const response = await axios(`spadeleaf-api.php?action=getResName&resId=${resId}&resJoin=${resJoin}`);
            setResName(response.data.resName);
          } catch (error) {
            console.log(error);
          }
    }
    fetchData();
  }, []);

    return (
      <header className="banner" style={{
        backgroundSize: "cover",
        backgroundImage: `url(
            "https://ik.imagekit.io/gycc9trp8/spadeleaf/restaurants/${resId}/banner.jpg"
        )`,
        backgroundPosition: "center center",
      }}
    >
       <div className="banner_contents">
          <h1 className="banner_title">{resName}</h1>
        </div>

      </header>
    );
  }
  
  export default Banner;