import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <h1>Welcome to Spadeleaf!</h1>
      <p>
        We're excited to introduce you to Spadeleaf, the ultimate solution for a streamlined and modern dining experience.
      </p>
      <p>
        At Spadeleaf, we make dining out simpler and more enjoyable by transforming the way you interact with menus and payments. Our QR menu system is designed to provide you with convenience and control, enhancing every aspect of your dining experience.
      </p>
      <h2>Why You’ll Love Using Spadeleaf:</h2>
      <ul>
        <li><strong>Effortless Bill Splitting</strong>: No more waiting for the waiter to handle bill splitting. With Spadeleaf, everyone in your group can order and pay directly from their own device. Each person can manage their part of the bill seamlessly, making group dining hassle-free.</li>
        <li><strong>Instant Updates</strong>: Stay informed with live updates on your order status. Track the progress of your order in real-time right from your cart, so you know exactly when your meal will be ready.</li>
        <li><strong>Seamless Ordering & Payment</strong>: Enjoy a unified experience where you can order and pay through a single interface. Our intuitive app allows you to browse the menu, customize your orders, and complete your payment all in one place, without any wait.</li>
        <li><strong>Personalized Experience</strong>: Receive tailored recommendations based on your preferences and previous orders. Our system helps you find exactly what you’re craving, ensuring a personalized dining experience every time.</li>
        <li><strong>Convenient Payments</strong>: Pay quickly and securely through the app. No need to wait for the check; simply settle your bill with a few taps and be on your way whenever you're ready.</li>
        <li><strong>Easy Feedback</strong>: Share your feedback effortlessly through the app. Your thoughts help restaurants improve and tailor their services to better meet your expectations.</li>
        <li><strong>Try It for Free</strong>: Explore the full features of Spadeleaf with our free one-month trial. Experience the convenience and see how it enhances your dining without any upfront commitment.</li>
        <li><strong>Proven Satisfaction</strong>: Discover how others have enjoyed the benefits of Spadeleaf through our success stories and testimonials. Join the growing number of satisfied customers who have revolutionized their dining experience.</li>
        <li><strong>Continual Improvement</strong>: We’re dedicated to evolving and enhancing our features based on your needs. Expect ongoing updates that will further enrich your dining experience with us.</li>
      </ul>
      <p>
        Thank you for choosing Spadeleaf! We look forward to making your dining experiences more enjoyable and efficient, and we’re excited to be a part of your next meal out.
      </p>
    </div>
  );
}

export default About;
