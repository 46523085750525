import React, {useState, useEffect} from "react";
import axios from "../services/axios";

import "./MenuItem.css";

import CartButton from "./CartButton";

function MenuItem( {category} ) {
    const[foodItems, setFoodItems] = useState( [] );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const[count, setCount] = useState(0);

    //function to pass to CartButton to fetch qty values
  const getCount = (count) => {
    setCount(count);
  }; 

    useEffect( () =>{
        async function fetchData() {
            try {
                const response = await axios("spadeleaf-api.php?action=getFoodItemByCust&ItemCategory="+category);
                setFoodItems(response.data);
                setLoading(false);
              } catch (error) {
                console.log(error);
                setError("Failed to fetch food items. Please try again later.");
                setLoading(false);
              }
        }
        fetchData();
    }, [category]); 

    if (loading) {
        return <div>Loading...</div>; // or render a loading spinner
    }
    
    if (error) {
        return <div>{error}</div>; // or render an error message
    }

    return (
        <div className="menuItemContainer">

            {foodItems.map(foodItem => (
                <div key={foodItem.ItemId} className="menuItem">
                    <div className="menuImageContainer">
                        <img
                            className="menuImage"
                            src={foodItem.ItemImage} alt={foodItem.ItemName}/>
                    </div>
                    <div className="menuItemDescription">
                        <h2>{foodItem.ItemName}</h2>
                        <h5>Price: ₹{foodItem.ItemPrice}</h5>
                        <p>{foodItem.ItemDescription}</p>
                        <div className="cartButtonContainer"> 
                            <CartButton updateCount={getCount} food={foodItem}/>
                        </div>
                                                
                    </div>
                </div>
            ))}

        </div>
    );
  }
  
  export default MenuItem;